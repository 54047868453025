import { SlideNumber, handleReadyEvent, configureSplideI18n } from "./slide-func.js";

document.addEventListener("DOMContentLoaded", function () {
  $(function () {
    configureSplideI18n();

    if (
      $("[data-special-info-box-slider] .splide__slide").length > 4 ||
      $(window).width() < 992
    ) {
      if ($("[data-promo-box-special]").hasClass("largeSpecialBox")) {
        new Splide(".largeBoxSliderMobile", {
          perPage: 4,
          pagination: false,
          arrows: true,
          gap: "20px",
          interval: 6000,
          breakpoints: {
            991: {
              perPage: 3,
            },
            767: {
              perPage: 2,
            },
            575: {
              pagination: false,
              perPage: 1,
              padding: {
                right: 80,
              },
            },
          },
        }).on("ready", function () {
            handleReadyEvent('.promoBox');
          }).mount({
            SlideNumber,
          });
      } else if ($("[data-promo-box-special]").hasClass("specialInfoBox")) {
        new Splide(".defaultSliderBox", {
          perPage: 4,
          pagination: true,
          arrows: true,
          gap: "20px",
          interval: 6000,
          breakpoints: {
            991: {
              perPage: 3,
              pagination: false,
            },
            767: {
              perPage: 2,
              pagination: false,
            },
            575: {
              pagination: false,
              perPage: 1,
              padding: {
                right: 100,
              },
            },
            399: {
              pagination: false,
              perPage: 1,
              gap: "10px",
              padding: {
                right: 50,
              },
            },
          },
        }).on("ready", function () {
          handleReadyEvent('.promoBox');
        }).mount();
      }
    }

    $("[data-special-info-box-slider]")
      .find(".splide__slide")
      .each(function () {
        $(this).find(".boxTitle").show();
      });

    if (
      $("[data-special-info-box-slider] .splide__slide").length <= 4 &&
      $(window).width() > 991
    ) {
      $("[data-short-version]").addClass("shortVersion");
      $("[data-add-row]").addClass("row");
      $("[data-add-col]").addClass("col-lg-3");
    }

    if (
      $("[data-special-info-box-slider].largeBoxSliderMobile .splide__slide")
        .length > 4 ||
      $(window).width() < 992
    ) {
      $("[data-short-version]").addClass("longVersion");
    }
  });
});
